




import { Component, Vue } from 'vue-property-decorator';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { getModule } from 'vuex-module-decorators';
import { WithMeta } from '@/core/vue.types';

import { MetaInfo } from 'vue-meta';

@Component
export default class SectionView extends Vue implements WithMeta {
  guideStore = getModule(GuideStore, this.$store);

  metaInfo(): MetaInfo {
    const section = this.guideStore.section;
    if (section) {
      return {
        title: section.sectionPageMetadata.title,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: section.sectionPageMetadata.description,
          },
          {
            vmid: 'keywords',
            name: 'keywords',
            content: section.sectionPageMetadata.keywords,
          },
        ],
      };
    }
    return {
      title: 'Support Portal',
    };
  }
}
